// Credential Container 
export const MbPubToken = "pk.eyJ1Ijoid2luc3Rvbm5nIiwiYSI6ImNrNmRpNTh2dDFuZG8zbXFlMTU4czIyeXgifQ.QpIr0jW1KYkipFhwfc9_yw"

// Map Style & Display Settings
// FIXME geolocateStyle currently not supported
export const GeolocateStyle = {
  float: 'left',
  margin: '50px',
  padding: '10px'
};


//
export const infoTimeStamp = (date) => {
  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let date_UTC = date.toLocaleDateString('vi-VN', options).split(",");

  let time_UTC = date.toLocaleTimeString('vi-VN', { timezone: 'UTC' }).split(":");

  let timeStamp_viVN =
    "<b>"
    + date_UTC[0]
    + "</b>,"
    + date_UTC[1]
    + ","
    + date_UTC[2]

    + "<br> <b>"
    + time_UTC[0]
    + "</b> Giờ <b>"
    + time_UTC[1]
    + "</b> Phút <b>"
    + time_UTC[2]
    + "</b> Giây";

  return timeStamp_viVN;
}