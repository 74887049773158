import React from 'react'

export const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <div className="modal-dialog">
        <div className="modal-header">
          <h4 className="modal-title">Đăng nhập để tiếp tục</h4>
        </div>
        <div className="modal-body">
          {children}
        </div>

        <div className="modal-footer">
          <button type="button" className="closeModal" onClick={handleClose}>Đóng</button>
        </div>
      </div>
    </div>
  );
};