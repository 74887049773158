import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'

export const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: (curUser) => {

            if (curUser.user.uid === "Dyev8VFoLIZ2S7bVNTOAtwTdRWe2") {}
            else {
                curUser.user.delete().then(function () {
                    alert('Tài khoản vô hiệu')

                }).catch(function (error) {
                    // TODO Handle error
                });
            }

            return false;
        },
        signInFailure: () => false,
    },
    signInFlow: 'popup',
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    'credentialHelper': 'none',
};



export const credential = {
    apiKey: "AIzaSyAjSwJnRPc5qzVhGPE2sbcl0EPm7h8YfF4",
    authDomain: "tts-derivative.firebaseapp.com",
    databaseURL: "https://tts-derivative.firebaseio.com",
    projectId: "tts-derivative",
};

// let fbInitHlr = firebase.initializeApp(fbCredInfo);

// const logInBE = new firebaseui.auth.AuthUI(firebase.auth());
// logInBE.start('#firebaseui-auth-container', fbUiCfg);

// // This function will trigger when there is a login event
// firebase.auth().onAuthStateChanged(function (curUsr) {
//     console.log(curUsr)

//     // Authorization state changes, sign in
//     if (curUsr) {
//         // If correct admin, queryAll
//         console.log("Sign In")


//         if (curUsr.uid === "Dyev8VFoLIZ2S7bVNTOAtwTdRWe2") {

//             // TODO change button state, color
//             // document.getElementById("access-btn-txt").innerHTML = "Đăng Xuất"

//             // TODO verify correct admin idToken
//             // var queryAllPoints = new queryAll(mainMap)
//             console.log("Query All Points")
//         }
//         else {
//             curUsr.delete().then(function () {
//                 console.log("Deleted New User")
//             }).catch(function (error) {
//                 // TODO Handle error
//             });

//         }

//     }
//     // Authorization state changes, sign out
//     else {
//         console.log("Sign Out")

//         // Re-render default Map, clear all markers
//         // mainMap.setCenter(new google.maps.LatLng(10.772984, 106.693677));
//         // while (markers.length) {
//         //     markers.pop().setMap(null);
//         // }

//         // Toggle logInBtn
//         // document.getElementById("access-btn-txt").innerHTML = "Đăng Nhập"
//         MainMap.showLoginMd();
//         // Re-render login FireBaseUI
//         logInBE.start('#firebaseui-auth-container', fbUiCfg);

//         // Cleanup alert
//         // window.alert("Đăng Xuất thành công !"); // Redundant Var

//     }
// });
