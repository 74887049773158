import React from 'react'

import mapboxCls from 'mapbox-gl'

import firebase from 'firebase/app';
import 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { Container, Button } from 'react-floating-action-button'
import { Modal } from './utils/LoginModal'

import * as AuthHlr from './utils/Authen'
import * as DisplayHlr from './utils/Display'

mapboxCls.accessToken = DisplayHlr.MbPubToken;
// Globalize handler
let map = null;
let geoLocCtrl = null;
let history = null;
let markers = [];

// Global Authentication Init
firebase.initializeApp(AuthHlr.credential);


class MainMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Login Modal Settings
      isSignedIn: false,
      isFetched: false,
      isPlotted: false,
      showMd: false,

      // Map Display Settings
      width: "100%",
      height: 900,
      zoom: 12,
      UsrTvlLog: [], // User Travel Log Marker array
      hcmc: {
        lng: 106.693677,
        lat: 10.772984,
      }
    };

    this.showLoginHlr = this.showLoginHlr.bind(this);
    this.hideLoginHlr = this.hideLoginHlr.bind(this);
  }

  showLoginHlr = () => {
    this.setState({ showMd: true });
  }

  hideLoginHlr = () => {
    this.setState({ showMd: false });
  }

  getRecent() {

    if (!this.state.isPlotted && this.state.isFetched) {

      // Reverse array to latest point on top
      history = JSON.parse(JSON.stringify(this.state.UsrTvlLog)).slice().reverse()

      history.forEach(function (el, idx) {

        let markerContainer = document.createElement('div');
        markerContainer.className = 'counter'
        markerContainer.innerText = idx + 1 // For better comprehension (start at 1)
        markerContainer.style.textAlign = 'center'
        markerContainer.style.fontWeight = 'bold'
        // markerContainer.innerHTML = 1;

        let markerPopup = new mapboxCls.Popup({ offset: 25 })
          .setHTML(DisplayHlr.infoTimeStamp(new Date(history[idx].created_at)))

        let marker = new mapboxCls.Marker(markerContainer, { draggable: true })
          .setLngLat([el.field2, el.field1])
          // .setLngLat(history)
          .setPopup(markerPopup) // sets a popup on this marker
          .addTo(map);

        markers.push(marker)
      });

    }
    // console.log(markers)

  }

  reCenter = () => {
    // Re-focus on latest point
    if (this.state.isPlotted) {
      map.setCenter([history[0].field2, history[0].field1]);
      map.setZoom(15)
    }
  }

  cleanLogout = () => {

    // Clear Markers
    markers.forEach(function (mkr) {
      mkr.remove()
    });

    // Default Display back to HCMC
    map.setCenter([this.state.hcmc.lng, this.state.hcmc.lat]);

    // Sign Out
    firebase.auth().signOut()

    // Additional Alert
    alert('Đăng xuất thành công !')
  }

  componentDidMount() {
    map = new mapboxCls.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [this.state.hcmc.lng, this.state.hcmc.lat],
      zoom: this.state.zoom,
      width: this.state.width,
      height: this.state.height
    });

    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2)
      });
    });

    geoLocCtrl = new mapboxCls.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });

    map.addControl(geoLocCtrl);


    // Listen to the Firebase Auth state and set the local state.
    // this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
    //   (user) => this.setState({ isSignedIn: !!user })
    // );
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user })

    })
  }

  // Un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  componentDidUpdate(pProps, pState) {
    if (!pState.isFetched && pState.isSignedIn && Object.keys(pState.UsrTvlLog).length === 0) {

      fetch('https://api.thingspeak.com/channels/901440/feeds.json?api_key=FPJ64BBHRB8G6ASP&results=10')
        .then(res => res.json())
        .then((geolocation) => {
          this.setState({ UsrTvlLog: geolocation.feeds, isFetched: true })
        })
        .catch(console.log)

    }
    else if (!pState.isPlotted && pState.isSignedIn && pState.isFetched && Object.keys(pState.UsrTvlLog).length !== 0) {
      this.getRecent()
      this.setState({ isPlotted: true })
    }

  }

  render() {
    // Initial state before login
    if (!this.state.isSignedIn) {
      return (
        <div>

          {/* Optional Coordinate topbar */}
          <div className='sidebarStyle'>
            <div>Kinh Độ: {this.state.hcmc.lng} | Vĩ Độ: {this.state.hcmc.lat} | Zoom: {this.state.zoom}</div>
          </div>

          <div ref={el => this.mapContainer = el} className='mapContainer' />

          {/* Login Hidden (default) Modal */}
          <Modal show={this.state.showMd} handleClose={this.hideLoginHlr} >
            <StyledFirebaseAuth uiConfig={AuthHlr.uiConfig} firebaseAuth={firebase.auth()} />
          </Modal>

          {/* FAB bottom-right */}
          <Container
            className="menu-login"
          >
            {/* TODO Switch logic to logged in */}
            {/* <Button
              className="btn-filter"
              tooltip="Chọn bộ lọc"
              icon="fas fa-sign-in-alt fa-2x"
              onClick={DisplayHlr.queryAll()}
            /> */}
            {/* END Switch logic  */}

            <Button
              className="btn-login"
              tooltip="Đăng Nhập"
              icon="fas fa-sign-in-alt fa-2x"
              onClick={this.showLoginHlr}
            />
            <Button
              className="btn-login-geolocate"
              tooltip="Định Vị"
              icon="fas fa-compass fa-2x"
              onClick={() => geoLocCtrl.trigger()}
            />
            <Button
              icon="fas fa-cog fa-3x"
            // rotate={true} 
            />
          </Container>

        </div>
      );
    }

    // Once logged in successfully
    return (
      <div>

        {/* Optional Coordinate topbar */}
        <div className='sidebarStyle'>
          <div>Kinh Độ: {this.state.hcmc.lng} | Vĩ Độ: {this.state.hcmc.lat} | Zoom: {this.state.zoom}</div>
        </div>

        <div ref={el => this.mapContainer = el} className='mapContainer' />

        {/* NOTE Missing Modal caller because no need to login here
        Logout button below */}

        {/* FAB bottom-right */}
        <Container
          className="menu-logout"
        >
          <Button
            className="btn-logout"
            tooltip="Đăng Xuất"
            icon="fas fa-sign-out-alt fa-2x"
            onClick={() => this.cleanLogout()}
          />
          <Button
            className="btn-fetch"
            tooltip="Gần Đây"
            icon="fas fa-map-pin fa-2x"
            onClick={() => this.reCenter()}
          />
          <Button
            className="btn-logout-geolocate"
            tooltip="Định Vị"
            icon="fas fa-compass fa-2x"
            onClick={() => geoLocCtrl.trigger()}
          />
          <Button
            icon="fas fa-cog fa-3x"
          // rotate={true} 
          />
        </Container>

      </div>

    )
  }
}

export default MainMap